import React from 'react'

import { ChakraProvider, Container, extendTheme, Flex, Spacer } from '@chakra-ui/react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { SiteTitleQuery } from '../../types/graphql-types'
import { MediaContextProvider } from '../Media'
import { Footer } from './footer'
import { Header } from './header'

const theme = extendTheme({
  components: {
    Heading: {
      baseStyle: {
        paddingBottom: '2rem',
      },
    },
    Text: {
      baseStyle: {
        paddingBottom: '1rem',
      },
    },
    Table: {
      baseStyle: {
        paddingBottom: '1rem',
      },
    },
  },
  styles: {
    global: {
      'html, body, #___gatsby,': {
        height: '100vh',
        width: '100%',
        background: '#9fb348',
      },
      a: {
        color: 'teal.500',
      },
    },
  },
})

const Layout: React.FC = ({ children }) => {
  const data = useStaticQuery<SiteTitleQuery>(graphql`
    query SiteTitle {
      desktop: file(relativePath: { eq: "background.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ChakraProvider theme={theme}>
      <MediaContextProvider>
        <BackgroundImage
          style={{ height: '100vh' }}
          Tag="div"
          fluid={data.desktop.childImageSharp.fluid}
          backgroundColor="#9fb348"
        >
          <div
            style={{
              height: '100vh',
              overflowY: 'scroll',
            }}
          >
            <Container maxW="container.lg" style={{ minHeight: '100vh' }}>
              <Flex direction="column" style={{ minHeight: '100vh' }}>
                <Header siteTitle={data.site.siteMetadata?.title || `Title`} />

                <main style={{ padding: '2rem' }}>{children}</main>
                <Spacer />
                <Footer />
              </Flex>
            </Container>
          </div>
        </BackgroundImage>
      </MediaContextProvider>
    </ChakraProvider>
  )
}

export default Layout
