import React, { FC } from 'react'

import {
  Container,
  Flex,
  Heading,
  Spacer,
  Stack,
  styled,
  Link,
  Box,
  useMediaQuery,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
} from '@chakra-ui/react'
import { graphql, useStaticQuery, GatsbyLinkProps } from 'gatsby'
import Img, { FixedObject, FluidObject } from 'gatsby-image'
import { HeaderQuery } from '../../types/graphql-types'
import { Media } from '../Media'

interface HeaderProps {
  siteTitle: string
}

export const Header: FC<HeaderProps> = ({ siteTitle }) => {
  const data = useStaticQuery<HeaderQuery>(graphql`
    query Header {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allPrismicNavigation {
        edges {
          node {
            data {
              main_navigation {
                link {
                  uid
                }
                title {
                  text
                }
              }
            }
          }
        }
      }
    }
  `)

  const [isDesktop] = useMediaQuery([`(min-width:48em)`])
  return (
    <>
      <Flex align="center" justify="center" flexWrap={'wrap'} padding="3rem">
        <Link href="/" flexBasis="30%" minW="sm">
          <Img fluid={data.logo.childImageSharp.fluid} />
        </Link>
      </Flex>
      <Media at="xs">
        <Box paddingTop="2rem">
          <Menu>
            <MenuButton as={Button}>Menü</MenuButton>
            <MenuList>
              {data.allPrismicNavigation.edges[0]!.node.data.main_navigation.map((edge) => {
                return (
                  <MenuItem as={Link} href={edge.link.uid}>
                    {edge.title[0].text}
                  </MenuItem>
                )
              })}
            </MenuList>
          </Menu>
        </Box>
      </Media>
      <Media greaterThan="xs">
        <nav>
          <Stack direction="row" spacing="1rem" height="3rem" align="center" justify="center" padding="0 1rem 2rem">
            {data.allPrismicNavigation.edges[0]!.node.data.main_navigation.map((edge) => {
              return (
                <Link fontSize="20px" href={`/${edge.link.uid}`} color="#67211D">
                  {edge.title[0].text}
                </Link>
              )
            })}
          </Stack>
          <Box height="0.3rem" width="100%" borderRadius="3px" background="#9FB348" />
        </nav>
      </Media>
    </>
  )
}
