import React, { FC } from 'react'

import { Stack, Link, Spacer, Text } from '@chakra-ui/react'
import { graphql, useStaticQuery } from 'gatsby'
import { FooterQuery } from '../../types/graphql-types'
import { Media } from '../Media'

interface FooterProps {}

export const Footer: FC<FooterProps> = () => {
  const data = useStaticQuery<FooterQuery>(graphql`
    query Footer {
      allPrismicNavigation {
        edges {
          node {
            data {
              footer_navigation {
                link {
                  uid
                }
                title {
                  text
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <footer style={{ padding: '2rem', width: '100%' }}>
      <Media at="xs">
        <Stack direction="column" spacing="1rem">
          <Link href="/anfahrt">Anfahrt</Link>
          <Link href="/foerderung">Förderung</Link>
          {data.allPrismicNavigation.edges[0].node.data.footer_navigation.map((nav) => {
            return <Link href={`/${nav.link.uid}`}>{nav.title[0].text}</Link>
          })}
          <Text>
            <strong>Telefon</strong> 05691 6217000
          </Text>
          <Text>
            <strong>Adresse</strong> Thieler Weg 1, 34454 Bad Arolsen
          </Text>
        </Stack>
      </Media>
      <Media greaterThan="xs">
        <Stack direction="row" spacing="1rem">
          <Link href="/anfahrt">Anfahrt</Link>
          <Link href="/foerderung">Förderung</Link>
          {data.allPrismicNavigation.edges[0].node.data.footer_navigation.map((nav) => {
            return <Link href={`/${nav.link.uid}`}>{nav.title[0].text}</Link>
          })}
          <Spacer />
          <Stack direction="row">
            <Text>
              <strong>Telefon</strong> 05691 6217000
            </Text>
            <Text>
              <strong>Adresse</strong> Thieler Weg 1, 34454 Bad Arolsen
            </Text>
          </Stack>
        </Stack>
      </Media>
    </footer>
  )
}
